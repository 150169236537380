<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="packingListsFiltered"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                    :style="{ 'background-color': '#eeeeee' }"
                >
                    <v-col cols="6" class="d-flex mb-n3">
                        <h1 class="mr-4">PACKING LISTS</h1>
                    </v-col>
                    <v-col cols="6" class="d-flex mb-n3">
                        <v-spacer />
                        <v-btn
                            rounded
                            color="primary"
                            @click="openCreatePackingList()"
                            class="mx-2"
                            v-if="
                                user.id == project.projectManager ||
                                    user.permissions.includes(
                                        'createPackingList'
                                    )
                            "
                        >
                            <v-icon>
                                mdi-plus
                            </v-icon>
                            {{ $vuetify.breakpoint.smAndDown ? '' : 'NEW' }}
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <!--HEADERS-->
            <template v-slot:[`header.code`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="search"
                    dense
                />
            </template>
            <template v-slot:[`header.workOrdersAndBoms`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="workOrderAndBomToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.createdBy`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="userToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.status`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="statusToFilter"
                    dense
                />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.createdBy`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">{{ findUserName(item.createdBy) }}</p>
                </div>
            </template>
            <template v-slot:[`item.workOrdersAndBoms`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ item.selectedWorkOrdersAndBomsString }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.project`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ item.project.reference }} - {{ item.project.name }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.createdOn`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ formatDate(item.createdOn._seconds) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.requestedShipmentDate`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ item.requestedShipmentDate }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.weight`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ totalPackingWeight(item).toFixed(1) }} kg
                    </p>
                </div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <div class="d-flex justify-center">
                    <v-chip
                        :color="statusColor[item.status]"
                        class="white--text text-capitalize"
                    >
                        {{ item.status }}
                    </v-chip>
                </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex justify-center">
                    <v-btn small icon @click="openProcessPackingList(item)">
                        <v-icon>{{
                            item.status !== 'completed'
                                ? 'mdi-pencil'
                                : 'mdi-eye'
                        }}</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="
                            item.status == 'inProgress' ||
                                item.status == 'rejected'
                        "
                        small
                        icon
                        @click="openDeletePackingList(item)"
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
        <v-alert
            v-if="activateAlert"
            type="success"
            color="primary"
            :style="{ position: 'absolute', right: '0px', bottom: '0px' }"
        >
            <v-row no-gutters>
                <v-col cols="11">
                    <p class="my-0">{{ alertMessage }}</p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="activateAlert = false"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <!--PACKING ORDER FORM-->
        <v-dialog
            v-model="packingListForm"
            :retain-focus="false"
            persistent
            fullscreen
        >
            <PackingForm
                v-if="packingListForm"
                :project="selectedProject"
                :originalOrder="selectedOrder"
                :onlySuggested="true"
                @close="closePackingListForm"
                @addPackingList="addPackingList"
                @replacePackingList="replacePackingList"
            />
        </v-dialog>
        <!--PROCESS PACKING ORDER-->
        <v-dialog
            v-model="processPackingList"
            :retain-focus="false"
            persistent
            fullscreen
        >
            <ProcessPackingPending
                v-if="processPackingList && selectedOrder.status == 'pending'"
                :originalOrder="selectedOrder"
                :project="selectedProject"
                @replacePackingList="replacePackingList"
                @close="closeProcessPackingList"
            />
            <ProcessPackingScheduled
                v-if="processPackingList && selectedOrder.status == 'scheduled'"
                :originalOrder="selectedOrder"
                :project="selectedProject"
                @replacePackingList="replacePackingList"
                @close="closeProcessPackingList"
            />
            <ProcessPackingReferred
                v-if="processPackingList && selectedOrder.status == 'referred'"
                :originalOrder="selectedOrder"
                :project="selectedProject"
                @replacePackingList="replacePackingList"
                @close="closeProcessPackingList"
            />
            <ProcessPackingShipped
                v-if="processPackingList && selectedOrder.status == 'shipped'"
                :originalOrder="selectedOrder"
                :project="selectedProject"
                @replacePackingList="replacePackingList"
                @close="closeProcessPackingList"
            />
            <ProcessPackingCompleted
                v-if="processPackingList && selectedOrder.status == 'completed'"
                :originalOrder="selectedOrder"
                :project="selectedProject"
                @replacePackingList="replacePackingList"
                @close="closeProcessPackingList"
            />
        </v-dialog>
        <!--Delete Packing Order-->
        <v-dialog
            v-model="deleteWarning"
            persistent
            max-width="400px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="deleteWarning">
                <v-card-title class="text-h5"
                    >Delete Packing Order</v-card-title
                >
                <v-card-text>
                    Are you sure you want to delete this packing order?
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        text
                        color="secondary"
                        @click="closeDeletePackingList"
                    >
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="deletePackingList"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import _ from 'lodash'
import API from '@/services/api'
import { mapMutations } from 'vuex'
import moment from 'moment'

export default {
    name: 'ProjectPackingLists',
    components: {
        PackingForm: () => import('@/components/Packing/PackingForm.vue'),
        ProcessPackingPending: () =>
            import('@/components/Packing/ProcessPackingPending.vue'),
        ProcessPackingScheduled: () =>
            import('@/components/Packing/ProcessPackingScheduled.vue'),
        ProcessPackingReferred: () =>
            import('@/components/Packing/ProcessPackingReferred.vue'),
        ProcessPackingShipped: () =>
            import('@/components/Packing/ProcessPackingShipped.vue'),
        ProcessPackingCompleted: () =>
            import('@/components/Packing/ProcessPackingCompleted.vue'),
    },
    props: {
        project: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        selectedProject: {},
        deleteWarning: false,
        selectedOrder: undefined,
        processPackingList: false,
        packingListForm: false,
        statusToFilter: undefined,
        projectToFilter: undefined,
        workOrderAndBomToFilter: undefined,
        userToFilter: undefined,
        loading: false,
        activateAlert: false,
        alertMessage: undefined,
        search: null,
        headers: [
            {
                text: 'ORDER',
                value: 'code',
                align: 'center',
                sortable: false,
            },
            {
                text: 'WOS - BOMS',
                value: 'workOrdersAndBoms',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CREATED BY',
                value: 'createdBy',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CREATED ON',
                value: 'createdOn',
                align: 'center',
                sortable: false,
            },
            {
                text: 'REQUESTED SHIPMENT DATE',
                value: 'requestedShipmentDate',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'center',
                sortable: false,
            },
            {
                text: 'WEIGHT',
                value: 'weight',
                align: 'center',
                sortable: false,
            },
            {
                text: 'STATUS',
                value: 'status',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
            },
        ],
        company: JSON.parse(localStorage.getItem('company')),
        statusColor: {
            inProgress: '#9E9FA0',
            pending: '#FF9800',
            referred: 'primary',
            scheduled: '#25539d',
            rejected: 'red',
            completed: 'black',
            shipped: '#27BC4F',
        },
        companyId: JSON.parse(localStorage.getItem('company')),
        packingLists: [],
        users: [],
        user: {},
        createPackingListPermission: false,
    }),
    computed: {
        packingListsFiltered() {
            let conditions = []
            if (this.search) {
                conditions.push(this.filterOrder)
            }
            if (this.workOrderAndBomToFilter) {
                conditions.push(this.filterWorkOrder)
            }
            if (this.projectToFilter) {
                conditions.push(this.filterProject)
            }
            if (this.statusToFilter) {
                conditions.push(this.filterStatus)
            }
            if (this.userToFilter) {
                conditions.push(this.filterByUser)
            }
            if (conditions.length > 0) {
                return this.packingLists.filter(order => {
                    return conditions.every(condition => {
                        return condition(order)
                    })
                })
            }
            this.sort()
            return this.packingLists
        },
    },
    async mounted() {
        try {
            this.loading = true
            const {
                data: { user },
            } = await API.getMyInfo()
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            this.user = user
            this.createPackingListPermission = this.user.permissions.includes(
                'createPackingList'
            )
            this.packingLists = await API.getPackingListsByProject(
                this.project.id
            )
            this.packingLists.forEach(pl => {
                pl.selectedWorkOrdersAndBomsString = ''
                for (let index in pl.selectedWorkOrders) {
                    if (
                        index == pl.selectedWorkOrders.length - 1 &&
                        !pl.selectedBOMs
                    ) {
                        pl.selectedWorkOrdersAndBomsString +=
                            pl.selectedWorkOrders[index].code
                    } else {
                        pl.selectedWorkOrdersAndBomsString +=
                            pl.selectedWorkOrders[index].code + ', '
                    }
                }
                for (let index in pl.selectedBOMs) {
                    if (index == pl.selectedBOMs.length - 1) {
                        pl.selectedWorkOrdersAndBomsString +=
                            pl.selectedBOMs[index].code
                    } else {
                        pl.selectedWorkOrdersAndBomsString +=
                            pl.selectedBOMs[index].code + ', '
                    }
                }
            })
            this.sort()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        openCreatePackingList() {
            this.selectedProject = this.project
            this.packingListForm = true
        },
        closeDeletePackingList() {
            this.selectedOrder = null
            this.deleteWarning = false
        },
        openDeletePackingList(item) {
            this.selectedOrder = _.cloneDeep(item)
            this.deleteWarning = true
        },
        addPackingList(newPackingList) {
            this.activateAlert = true
            this.alertMessage = 'New Packing List Successfully Created'
            newPackingList.selectedWorkOrdersAndBomsString = ''
            for (let woIndex in newPackingList.selectedWorkOrders) {
                if (
                    woIndex == newPackingList.selectedWorkOrders.length - 1 &&
                    !newPackingList.selectedBOMs
                ) {
                    newPackingList.selectedWorkOrdersAndBomsString +=
                        newPackingList.selectedWorkOrders[woIndex].code
                } else {
                    newPackingList.selectedWorkOrdersAndBomsString +=
                        newPackingList.selectedWorkOrders[woIndex].code + ', '
                }
            }
            for (let bomIndex in newPackingList.selectedBOMs) {
                if (bomIndex == newPackingList.selectedBOMs.length - 1) {
                    newPackingList.selectedWorkOrdersAndBomsString +=
                        newPackingList.selectedBOMs[bomIndex].code
                } else {
                    newPackingList.selectedWorkOrdersAndBomsString +=
                        newPackingList.selectedBOMs[bomIndex].code + ', '
                }
            }
            this.packingLists.push(newPackingList)
            this.packingListForm = false
        },
        replacePackingList(packingList) {
            const index = this.packingLists.findIndex(
                p => p.id == packingList.id
            )
            if (index >= 0) {
                this.packingLists.splice(index, 1, {
                    ...this.packingLists[index],
                    ...packingList,
                })

                this.packingLists[index].selectedWorkOrdersAndBomsString = ''
                for (let woIndex in this.packingLists[index]
                    .selectedWorkOrders) {
                    if (
                        woIndex ==
                            this.packingLists[index].selectedWorkOrders.length -
                                1 &&
                        !this.packingLists[index].selectedBOMs
                    ) {
                        this.packingLists[
                            index
                        ].selectedWorkOrdersAndBomsString += this.packingLists[
                            index
                        ].selectedWorkOrders[woIndex].code
                    } else {
                        this.packingLists[
                            index
                        ].selectedWorkOrdersAndBomsString +=
                            this.packingLists[index].selectedWorkOrders[woIndex]
                                .code + ', '
                    }
                }
                for (let bomIndex in this.packingLists[index].selectedBOMs) {
                    if (
                        bomIndex ==
                        this.packingLists[index].selectedBOMs.length - 1
                    ) {
                        this.packingLists[
                            index
                        ].selectedWorkOrdersAndBomsString += this.packingLists[
                            index
                        ].selectedBOMs[bomIndex].code
                    } else {
                        this.packingLists[
                            index
                        ].selectedWorkOrdersAndBomsString +=
                            this.packingLists[index].selectedBOMs[bomIndex]
                                .code + ', '
                    }
                }
            }
        },
        openProcessPackingList(item) {
            this.selectedOrder = _.cloneDeep(item)
            this.selectedProject = _.cloneDeep(this.project)
            if (item.status == 'inProgress' || item.status == 'rejected') {
                this.packingListForm = true
            } else {
                this.processPackingList = true
            }
        },
        closeProcessPackingList() {
            this.selectedOrder = null
            this.selectedProject = null
            this.processPackingList = false
        },
        closePackingListForm() {
            this.selectedOrder = null
            this.selectedProject = null
            this.packingListForm = false
        },
        filterOrder(order) {
            return order.code
                .toString()
                .toLowerCase()
                .includes(this.search.toLowerCase())
        },
        filterByUser(order) {
            return this.findUserName(order.createdBy)
                .toString()
                .toLowerCase()
                .includes(this.userToFilter.toLowerCase())
        },
        filterStatus(order) {
            return order.status
                .toLowerCase()
                .includes(this.statusToFilter.toLowerCase())
        },
        filterWorkOrder(order) {
            return order.selectedWorkOrdersAndBomsString
                .toLowerCase()
                .includes(this.workOrderAndBomToFilter.toLowerCase())
        },
        filterProject(order) {
            return (
                order.project.name
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase()) ||
                order.project.reference
                    .toString()
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase())
            )
        },
        sort() {
            this.packingLists.sort(
                (a, b) =>
                    (b.createdOn.seconds || b.createdOn._seconds) -
                    (a.createdOn.seconds || a.createdOn._seconds)
            )
        },
        formatDate(seconds) {
            return moment.unix(seconds).format('YYYY-MM-DD')
        },
        findUserName(id) {
            let userName = ''
            const user = this.users.find(u => u.id == id)
            if (user) {
                userName = user.name
            }
            return userName
        },
        async deletePackingList() {
            try {
                this.loading = true
                const packingList = await API.deletePackingList({
                    id: this.selectedOrder.id,
                    projectId: this.selectedOrder.projectId,
                })
                this.selectedOrder = null
                this.closeDeletePackingList()
                this.removePackingList(packingList)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        removePackingList(packingList) {
            this.packingLists = this.packingLists.filter(
                pl => pl.id != packingList.id
            )
        },
        totalPackingWeight(item) {
            let mass = Number(item.totalMass || 0)
            if (item.packages) {
                item.packages.forEach(pck => {
                    mass += Number(pck.packageDimensions.mass || 0)
                })
            }
            return mass
        },
    },
}
</script>

<style>
.v-input .v-label {
    font-size: 12px;
}
</style>
